import React from 'react';

const Slide = ({ content, imageURL, title, font=25}) => {
  return (
    <div className="slide" style={{width: '80vw', height:
    700}}>
      <div style={{flexDirection: "column", }}>
        <h1 style={{ fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' }} >{title}</h1>  
        <div style={{ fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: font, whiteSpace: 'pre-wrap', textAlign: "left" }} >
          {content}
        </div>
      </div>
      {imageURL && <img src={imageURL} alt={title} className="slide-image" />}

      
      
    </div>
    
  );
};

export default Slide;