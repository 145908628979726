import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import Slide from './Slide';
import './App.css';
import appleStore from './images/IMG_0584.JPG';
import ios7 from './images/IMG_0873.JPG';
import laptop from './images/IMG_4375 2.jpg';
import iphone8 from './images/IMG_0334.jpg';
import rejected from './images/Screenshot 2023-11-09 at 12.50.34 PM.png';
import loop from './images/IMG_0837.jpeg';
import park from './images/IMG_0211.jpeg';
import MobileMessage from './MobileMessage';


const slides = [
  { id: 0, content: '-Caden Chorlog', imageURL: appleStore, title: "Dear Apple, thank you."  },
  { id: 1, content: 'for fostering my passions.', imageURL: ios7, title: "Thank you" },
  { id: 2, content: 'for creating tools that change the world.', imageURL: laptop, title: "Thank you" },
  { id: 2, content: 'for inspiring me to dig deeper.', imageURL: iphone8, title: "Thank you" },
  { id: 2, content: 'for challanging me to do better.', imageURL: rejected, title: "Thank you" },
  { id: 2, content: 'for making me go the extra 650 miles.', imageURL: loop, title: "Thank you" },

  { id: 2, content: 'I began this application with a standard cover letter and quickly saw that it just didn\'t do justice to my real enthusiasm for the opportunity at Apple. It\'s important for me to show how deeply Apple has influenced me. From childhood, Apple\'s innovation captured my attention; I never missed a live event, watching with immense anticipation of the next big thing.\n\nBy age eight, I was already diving deep into the tech world, coding my first iOS app with Xcode. This wasn\'t just a hobby; it sparked a serious interest in technology that set the path for the rest of my life. Fast forward to my teenage years, and after a summer of saving from odd jobs, I bought my first MacBook. It was more than a laptop—it was my tool to create.\n\nAs my skills and interests developed, I wanted to give back. I started fixing iPhones, extending my expertise into my neighborhood. It was about more than repairs; it was about bringing back the experience of a pristine iPhone. The joy on people’s faces made me feel so glad that I could make such a positive experience from something so small.\n\nNow, as a college student studying computer science, I\'m deepening my understanding of technology. I see Apple as a platform to apply my knowledge and passion for Apple products, sharing what I know with customers and learning from the Apple environment. It\'s an opportunity to bridge my academic learning with practical experience, enriching both my own development and the customer experience.\n\nJoining the Apple team means continuing my journey with a company that\'s been a part of my life for so long. It\'s about leveraging my know how to enhance the Apple experience and connect with others who love these products as much as I do.\n\nThank you\n\n- Caden\n', font: 18, title: "Thank you." },
  // ...add more slides as needed
];

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

const App = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [goingForward, setGoingForward] = useState(true);

  const goToSlide = (slideIndex) => {
    setGoingForward(slideIndex > index);
    navigate(`/slide${slideIndex}`);
    setIndex(slideIndex);
  };

  // Animation transitions using react-spring
  const transitions = useTransition(index, {
    from: { opacity: 0, position: "absolute", transform: `translate3d(${goingForward ? '100%' : '-100%'},0,0)` },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' }, // corrected to 0% to center the slide
    leave: { opacity: 0, transform: `translate3d(${goingForward ? '-100%' : '100%'},0,0)` },
    config: { tension: 100, friction: 10 },
  });

  return (
    <div className="app">
      <MobileMessage />
      <button 
        className="control left" 
        onClick={() => goToSlide(index - 1)}
        disabled={index === 0}
      >
        ‹
      </button>
      <button 
        className="control right" 
        onClick={() => goToSlide(index + 1)}
        disabled={index === slides.length - 1}
      >
        ›
      </button>
      {transitions((style, i) => (
        <animated.div style={style}>
          <Slide content={slides[i].content} imageURL={slides[i].imageURL} title={slides[i].title} font={slides[i].font} />
        </animated.div>
      ))}
    </div>
  );
};

export default AppWrapper;
